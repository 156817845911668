<template>
  <div
    class="d-flex align-center cursor-pointer overflow-hidden"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <!-- If possible show an image avatar -->
    <v-avatar
      :size="small ? 30 : 36"
      color="secondary"
      @click="handleClick"
    >
      <img
        v-if="data.picture"
        :src="data.picture ? proxyUrl(data.picture) : '/img/gray-square.png'"
      />
      <span
        v-else
        class="white--text text-h6"
      >
        {{ String(data.username ? data.username[0] : (data.fullname ? data.fullname[0] : '')).toLocaleUpperCase() }}
      </span>
    </v-avatar>

    <!-- Next show the name and username next to each other -->
    <div
      v-if="!avatarOnly"
      :class="{ 'blurred': isBlurred, 'ml-3': !small, 'ml-2': small }"
      @click="handleClick"
    >
      <div
        class="line-height-1 d-flex align-center font-weight-bold white-space-nowrap"
        :class="{ 'text-subtitle-2': small, 'text-subtitle-1': !small }"
      >
        {{ reverseNameAndUsername ? data.fullname || '' : data.username || '' }}

        <v-icon
          v-if="data.is_verified"
          class="ml-1"
          color="blue"
          small
        >verified</v-icon>
      </div>
      <div
        class="line-height-1 white-space-nowrap"
        :class="{ 'text-caption': small, 'text-subtitle-2 font-weight-medium mt-1': !small }"
      >
        {{ reverseNameAndUsername ? data.username || '' : data.fullname || '' }}
      </div>
    </div>

    <!-- If it's closeable -->
    <div
      v-if="closeable"
    >
      <v-icon
        class="ml-3"
        :small="small"
        @click="$emit('close')"
      >
        close
      </v-icon>
    </div>

    <!-- A slot option to add custom items -->
    <slot></slot>
  </div>
</template>

<script>
// Export this SFC
export default {
  // Name of the component
  name: "ProfileChip",

  // Accept incoming data from parent
  props: {
    // The data object
    data: {
      type: Object,
      required: true
    },

    // The platform this profile belongs to
    platform: {
      type: String,
      required: false
    },

    // Whether or not clicking on it should redirect
    redirectOnClick: {
      type: Boolean,
      required: false,
      default: true
    },

    // Whether or not the text should be blurred
    isBlurred: {
      type: Boolean,
      required: false,
      default: false
    },

    // Whether or not to let click work
    disableClick: {
      type: Boolean,
      required: false,
      default: false
    },

    reverseNameAndUsername: {
      type: Boolean,
      required: false,
      default: false
    },

    // Whether or not to show a small version
    small: {
      type: Boolean,
      required: false,
      default: false
    },

    avatarOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    closeable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define computable properties
  computed: {
    computedPlatform() {
      return this.platform || this.data.platform
    }
  },

  // Define methods object
  methods: {
    /**
     * When the chip is clicked, handle the event
     *
     * @returns {void}
     */
    handleClick() {
      // If we should react
      if (this.redirectOnClick && !this.isBlurred && !this.disableClick) {
        // If there's an URL, then use just that
        if (this.data?.url) {
          // Open the window
          window.redirectToWeb(this.data.url)
          return
        }

        // Redirect then
        const map = {
          tiktok: "https://tiktok.com/@",
          instagram: "https://instagram.com/",
          youtube: "https://youtube.com/channel/",
          snapchat: "https://www.snapchat.com/add/",
          twitter: "https://twitter.com/",
          linkedin: "https://linkedin.com/in/",
        }

        // Now open the window
        window.redirectToWeb(`${map[this.computedPlatform]}${this.computedPlatform === 'youtube' ? (this.data.user_id || this.data.username) : this.data.username}`)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.blurred
  filter blur(3px)
</style>
